<template>
  <div class="col-md-12">
    <table class="table">
      <tbody>
        <tr>
          <td>
            <el-tooltip :content="this.$slots.accumulatedBalance_tt" :open-delay="100" placement="top" popper-class="adjust-width down-bit">
              <i class="nc-icon nc-alert-circle-i"></i>
            </el-tooltip>
            &nbsp;
            <slot name="accumulatedBalance"></slot>
          </td>
          <td class="text-right" v-if="stats.accumulatedBalance" :class="(stats.accumulatedBalance >= 0) ? 'text-success':'text-danger'">
            {{ (stats.accumulatedBalance * 100).toFixed(2) }}%
          </td>
          <td class="text-right" v-else>
            <slot name="notavailable"></slot>
          </td>
        </tr>

        <tr>
          <td>
            <el-tooltip :content="this.$slots.monthsSinceInception_tt" :open-delay="100" placement="top" popper-class="adjust-width down-bit">
              <i class="nc-icon nc-alert-circle-i"></i>
            </el-tooltip>
            &nbsp;
            <slot name="monthsSinceInception"></slot>
          </td>
          <td class="text-right" v-if="stats.monthsSinceInception">
            {{ stats.monthsSinceInception }}
          </td>
          <td class="text-right" v-else>
            <slot name="notavailable"></slot>
          </td>
        </tr>
        <tr>
          <td>
            <el-tooltip :content="this.$slots.maxEffectiveDrawdown_tt" :open-delay="100" placement="top" popper-class="adjust-width down-bit">
              <i class="nc-icon nc-alert-circle-i"></i>
            </el-tooltip>
            &nbsp;
            <slot name="maxEffectiveDrawdown"></slot>
          </td>
          <td class="text-right" v-if="stats.maxEffectiveDrawdown >= 0" :class="(stats.maxEffectiveDrawdown <= 0.3) ? 'text-success':'text-danger'">
            {{ (stats.maxEffectiveDrawdown * 100).toFixed(2) }}%
          </td>
          <td class="text-right" v-else>
            <slot name="notavailable"></slot>
          </td>
        </tr>
        <tr>
          <td>
            <el-tooltip :content="this.$slots.maxEffectiveLeverageUsed_tt" :open-delay="100" placement="top" popper-class="adjust-width down-bit">
              <i class="nc-icon nc-alert-circle-i"></i>
            </el-tooltip>
            &nbsp;
            <slot name="maxEffectiveLeverageUsed"></slot>
          </td>
          <td class="text-right" v-if="stats.maxEffectiveLeverageUsed" :class="(stats.maxEffectiveLeverageUsed <= 10) ? 'text-success':'text-danger'">
            {{ stats.maxEffectiveLeverageUsed.toFixed(2) }}
          </td>
          <td class="text-right" v-else>
            <slot name="notavailable"></slot>
          </td>
        </tr>
        <tr>
          <td>
            <el-tooltip :content="this.$slots.riskRatio_tt" :open-delay="100" placement="top" popper-class="adjust-width down-bit">
              <i class="nc-icon nc-alert-circle-i"></i>
            </el-tooltip>
            &nbsp;
            <slot name="riskRatio"></slot>
          </td>
          <td class="text-right" v-if="stats.riskRatio" :class="(stats.riskRatio <= 1) ? 'text-success':'text-danger'">
            {{ stats.riskRatio.toFixed(2) }}
          </td>
          <td class="text-right" v-else>
            <slot name="notavailable"></slot>
          </td>
        </tr>
        <tr>
          <td>
            <el-tooltip :content="this.$slots.equityToFollow_tt" :open-delay="100" placement="top" popper-class="adjust-width down-bit">
              <i class="nc-icon nc-alert-circle-i"></i>
            </el-tooltip>
            &nbsp;
            <slot name="equityToFollow"></slot>
          </td>
          <td class="text-right" v-if="stats.suggestedEquity" :class="(stats.suggestedEquity >= 0) ? 'text-success':'text-danger'">
            ${{ stats.suggestedEquity }}
          </td>
          <td class="text-right" v-else>
            <slot name="notavailable"></slot>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
  import {Tooltip} from 'element-ui'

  export default {
    components: {
      [Tooltip.name]: Tooltip
    },
    name: 'StrategyStats',
    props: [ 'stats' ]
  }
</script>
<style lang="scss">
  .adjust-width {
    width: 325px;
  }
</style>
