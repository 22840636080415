<template>
  <div class="text-center" style="height: 255px;" v-if="data.length == 0">
    <h5 style="position: relative;top: 85px;">
      <slot/>
    </h5>
  </div>
  <div v-else>
    <line-chart :height="200"
                :labels="labels"
                :datasets="datasets"
                :extra-options="options">
    </line-chart>
    <br>
    <div class="row">
      <div class="col-12 small text-muted move-right">
        {{ info.created_dt }}, {{ info.lastupdate }}
      </div>
<!--      <div class="col-4">-->
<!--        <div class="float-right circle-icon">-->
<!--          <el-tooltip :content="reporting_status" :open-delay="100" popper-class="adjust-width" placement="top">-->
<!--            <i class="fa fa-circle" :class="`text-${status_type}`"></i>-->
<!--          </el-tooltip>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <br>
  </div>
</template>

<script scoped>
import LineChart from 'src/components/UIComponents/Charts/LineChart';
import {Tooltip} from 'element-ui';

export default {
  components: {LineChart, [Tooltip.name]: Tooltip},
  name: 'StrategyChart',
  props: ['labels', 'data', 'datasets', 'options', 'info', 'reporting_status'],
  data() {
    return {
      status_type: 'success'
    }
  },
  mounted() {
    if (this.info.orders_fail && this.info.symbol_price_fail && this.info.trade_history_fail) {
      this.status_type = 'danger';
    } else if (this.info.orders_fail || this.info.symbol_price_fail || this.info.trade_history_fail) {
      this.status_type = 'warning';
    }
  }
}

</script>
<style scoped>
.adjust-width {
  width: 325px;
}

.circle-icon {
  position: relative;
  right: 15px;
}

.move-right {
  position: relative;
  left: 15px;
}

.Blink {
  animation: blinker 0.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

</style>
